import React from 'react';
import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';

export default () => (
  <>
    <Heading>TERMS & CONDITIONS</Heading>

    <Paragraph>
      These Terms and Conditions govern the use of this website. Please read them carefully as by using the site you
      indicate your acceptance of them. These Terms of Use refer to the following additional terms, which also apply to
      your use of our site:
    </Paragraph>

    <ul>
      <li>
        our Privacy Policy, which sets out the terms on which we process any personal data we collect from you, or that
        you provide to us. By using our site, you consent to such processing and you warrant that all data provided by
        you is accurate;
      </li>
      <li>our Cookie Policy, which sets out information about the cookies on our site.</li>
    </ul>

    <Paragraph>
      Blackhorsemills.com is a site operated by Legal & General Property Limited in its capacity as investment advisor
      to Cetza Trustees V3 Limited and Cetza Trustees V4 Limited in their capacity as joint trustees of the Roseberry
      Place Property Unit Trust.
    </Paragraph>
    <Paragraph>
      Legal & General Property Limited is registered in England and Wales under company number 02091897 and has its
      registered office at One Coleman Street, London EC2R 5AA.
    </Paragraph>

    <Heading tag="h3">GENERAL PROVISIONS</Heading>

    <Paragraph>
      We reserve the right in our absolute discretion at any time and without notice to remove, amend or vary any of the
      content which appears on any page of this site, including any terms and conditions. Any changes to these terms and
      conditions will be posted on this site and by continuing to use this site following any such change you will agree
      to be bound by the revised conditions.
    </Paragraph>
    <Paragraph>
      Whilst we have taken all reasonable steps to ensure that the information contained within the pages of this site
      is accurate, current and complies with the relevant legislation and regulations, no warranty is given and no
      representation is made regarding the accuracy of or completeness of this site. We accept no liability for any
      losses or damages (whether direct, indirect, special, consequential or otherwise) arising out of errors or
      omissions contained in this website. Content on our site is not intended to amount to advice on which you should
      rely and you should obtain advice before taking, or refraining from, any action on the basis of the content on our
      site.
    </Paragraph>
    <Paragraph>
      Further, no warranty is given that this site shall be available on an uninterrupted basis and no liability can be
      accepted in respect of losses or damages arising out of such unavailability.
    </Paragraph>

    <Heading tag="h3">COPYRIGHT AND TRADE MARKS</Heading>

    <Paragraph>
      All trade marks, service marks, company names or logos are the property of their respective holders and no
      permission is given by us in respect of the use of any such trade marks, service marks, company names or logos and
      such use may constitute an infringement of the holders rights.
    </Paragraph>
    <Paragraph>
      Reproduction of the pages of this site in whole or in part, without our prior written consent, is strictly
      prohibited unless for private, non-commercial viewing purposes.
    </Paragraph>

    <Heading tag="h3">LINKING TO YOUR SITE</Heading>

    <Paragraph>
      You may link to our home page, provided you do so in a way that is fair and legal and does not damage our
      reputation or take advantage of it.
    </Paragraph>
    <Paragraph>
      You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our
      part where none exists.
    </Paragraph>
    <Paragraph>
      Our site must not be framed on any other site, nor may you create a link to any part of our site other than the
      home page.
    </Paragraph>
    <Paragraph>We reserve the right to withdraw linking permission without notice.</Paragraph>
    <Paragraph>
      If you wish to make any use of content on our site other than that set out above, please contact
      info@blackhorsemills.com.
    </Paragraph>

    <Heading tag="h3">VIRUSES</Heading>

    <Paragraph>We do not guarantee that our site will be secure or free from bugs or viruses.</Paragraph>
    <Paragraph>
      You are responsible for configuring your information technology, computer programmes and platform in order to
      access our site. You should use your own virus protection software.
    </Paragraph>
    <Paragraph>
      You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material which
      is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server
      on which our site is stored or any server, computer or database connected to our site. You must not attack our
      site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you
      would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant
      law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In
      the event of such a breach, your right to use our site will cease immediately.
    </Paragraph>

    <Heading tag="h3">LIMITATION OF OUR LIABILITY</Heading>

    <Paragraph>
      Nothing in these Terms & Conditions shall attempt to exclude liability for death or personal injury or for
      fraudulent misrepresentation.
    </Paragraph>

    <Paragraph>
      To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may
      apply to our site or any content on it, whether express or implied.
    </Paragraph>
    <Paragraph>
      We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach
      of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
    </Paragraph>
    <ul>
      <li>use of, or inability to use, our site; or</li>
      <li>use of or reliance on any content displayed on our site.</li>
    </ul>
    <Paragraph>
      Please note that we only provide our site for domestic and private use. You agree not to use our site for any
      commercial or business purposes, and we have no liability to you for any loss of profit, loss of business,
      business interruption, or loss of business opportunity.
    </Paragraph>
    <Paragraph>
      We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other
      technologically harmful material that may infect your computer equipment, computer programs, data or other
      proprietary material due to your use of our site or to your downloading of any content on it, or on any website
      linked to it.
    </Paragraph>
    <Paragraph>
      We assume no responsibility for the content of websites linked on our site. Such links should not be interpreted
      as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from
      your use of them.
    </Paragraph>
    <Paragraph>
      Different limitations and exclusions will apply to our liability arising as a result of any tenancy we grant to
      you. These will be set out in the tenancy agreement itself.
    </Paragraph>
    <Heading tag="h3">APPLICABLE LAW</Heading>

    <Paragraph>
      These Terms and Conditions shall be governed by and construed in accordance with the law of England and you hereby
      submit to the exclusive jurisdiction of the English courts.
    </Paragraph>
  </>
);
