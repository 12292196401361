import React from 'react';
import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';

export default () => (
  <>
    <Heading>PRIVACY POLICY</Heading>

    <Heading tag="h3">INTRODUCTION</Heading>

    <Paragraph>
      Legal & General Property Limited (a company incorporated in England and Wales with company registration number
      02091897 (in its capacity as investment advisor to Cetza Trustees V3 Limited and Cetza Trustees V4 Limited in
      their capacity as joint trustees of the Walthamstow Property Unit Trust)) (“Legal & General”, “we” or “us”) is
      committed to protecting your privacy and keeping your personal information secure.
    </Paragraph>
    <Paragraph>
      This privacy policy (“Policy”) outlines the basis on which we collect, store and use your personal information.
      Any personal information you supply to us via our websites (and by any other means) will be treated in accordance
      with any applicable data protection laws (including the General Data Protection Regulation (EU) 2016/679 (known as
      the “GDPR”)) and other legislation intended to protect your personal information and privacy, in each case as may
      be amended, replaced or in force from time to time. If we hold information about you, we’ll only use this for the
      purposes you’ve agreed to as set out in this Policy. This Policy was last updated on 22 May 2018. We may update
      this Policy, so please check this page from time to time to make sure that you’re happy with any changes.
    </Paragraph>

    <Heading tag="h3">WHAT INFORMATION WE COLLECT ABOUT YOU AND HOW AND WHY WE COLLECT IT</Heading>

    <Heading tag="h4">1. When you visit our websites</Heading>

    <Paragraph>
      You may choose to give us certain information about you when you visit our websites, including your:
    </Paragraph>

    <ul>
      <li>name and contact information including email address;</li>
      <li>demographic information such as postcode, preferences and interests;</li>
      <li>
        details of your visits to our website and the resources that you access, including, but not limited to, traffic
        data, location data, internet log information, cookies, weblogs and other communication data.
      </li>
    </ul>

    <Paragraph>We may collect this information from you in order to:</Paragraph>

    <ul>
      <li>administer the online application process;</li>
      <li>identify general customer trends;</li>
      <li>track your internet browsing (in accordance with our Cookie Policy);</li>
      <li>improve our service to you; and</li>
      <li>communicate with you.</li>
    </ul>

    <Heading tag="h4">2. When reserving and staying at an apartment</Heading>

    <Paragraph>
      When reserving and staying at one of our apartments, we may collect the following information from you on our
      application forms, declarations or notices:
    </Paragraph>

    <ul>
      <li>name and contact information including, phone number and email address;</li>
      <li>demographic information such as postcode, preferences and interests;</li>
      <li>details of your stay in our apartments and the amenities that you access;</li>
      <li>CCTV footage; and</li>
      <li>(only if you are injured at one of our managed apartment buildings) data concerning health.</li>
    </ul>

    <Paragraph>We may collect this information from you in order to:</Paragraph>
    <ul>
      <li>provide you with access to one of our apartments;</li>
      <li>provide you with access to one of our services that you have requested (e.g. our amenities);</li>
      <li>comply with our legal health and safety obligations</li>
    </ul>

    <Heading tag="h4">3. When emailing us</Heading>
    <Paragraph>
      If you communicate with us by email, we may collect certain information from you, including your:
    </Paragraph>

    <ul>
      <li>name and contact information (as set out in your email to us);</li>
      <li>opinions and feedback on your experience of our services; and</li>
      <li>any other personally identifiable information you choose to share in your email to us.</li>
    </ul>
    <Paragraph>We will collect this information in order to:</Paragraph>
    <ul>
      <li>understand your needs and provide you with a better service;</li>
      <li>reply to your email and effectively address your concerns;</li>
      <li>analysis and to assist us with improving our processes, products and services;</li>
      <li>keep accurate business records; and</li>
      <li>
        (where we have legitimate authority to do so) send you promotional emails about new products, special offers or
        other information we think might be of interest, using the email address you provided.
      </li>
    </ul>
    <Heading tag="h4">4. When applying for a job</Heading>
    <Paragraph>
      You may choose to give us certain information about you when you apply for a job with us, including your:
    </Paragraph>

    <ul>
      <li>title, forename and last name;</li>
      <li>contact phone number;</li>
      <li>email address;</li>
      <li>ethnicity and race; and</li>
      <li>current and previous employment information.</li>
    </ul>

    <Paragraph>We may collect this information about you to:</Paragraph>
    <ul>
      <li>assess your eligibility to work in the UK;</li>
      <li>assess whether you meet the job specification criteria; and</li>
      <li>
        contact you to arrange interviews or assessments or to communicate our decision in relation to your application.
      </li>
    </ul>

    <Heading tag="h3">HOW WE MAY SHARE YOUR INFORMATION</Heading>
    <Paragraph>
      We may give general statistics about our customers, sales, and related site information to other companies however
      these statistics will not include any information that personally identifies you, except as we have stated below.
      In particular, we may share your personal information with third-parties such as other entities within our group
      of companies or third party service providers.
    </Paragraph>

    <Heading tag="h4">1. Group companies</Heading>
    <Paragraph>
      We may share your information with other entities within our group of companies including for example Legal &
      General Investment Management Limited (no 2091894), LGIM Real Assets (Operator) Limited (no 5522016) and Legal &
      General Group PLC (no 1417162).
    </Paragraph>
    <Heading tag="h4">2. Third-party service providers</Heading>
    <Paragraph>
      We may, where necessary, share your personal information with third-party service providers, suppliers, partners,
      associated organisations and agents, for example, providing personal information to repair and maintenance
      contractors.
    </Paragraph>
    <Heading tag="h4">3. Third-party advertisers</Heading>
    <Paragraph>
      We may share your personal information with other entities for digital advertising purposes (such as Facebook or
      Google) to assist us in finding other people with similar interests to our current customers and to bring you
      relevant adverts on our websites.
    </Paragraph>
    <Heading tag="h4">4. Where required to do so</Heading>
    <Paragraph>
      In exceptionally rare circumstances, we may be required to disclose your information under a court order or if
      requested by the police or other regulatory or governmental authority.
    </Paragraph>

    <Heading tag="h3">HOW WE PROTECT YOUR INFORMATION</Heading>
    <Paragraph>
      We ensure that all information that you provide us is stored using technical measures that are appropriate
      considering the nature, scope, context and purpose for which we are using it.
    </Paragraph>

    <Heading tag="h3">TRANSFERS OUTSIDE OF THE EEA</Heading>
    <Paragraph>
      We may transfer the information that we collect about you outside of the European Economic Area ("EEA") from time
      to time. This is due to the global nature of the internet. For instance, we may store certain information about
      you on servers which are located in the USA. In order to safeguard your information, we ensure that the entity
      that we are transferring your information to either:
    </Paragraph>
    <ul>
      <li>entered into the EU Model Clauses with us;</li>
      <li>entered into Binding Corporate Rules with us; or</li>
      <li>is listed on the EU-US Privacy Shield.</li>
    </ul>

    <Heading tag="h3">RETENTION</Heading>
    <Paragraph>
      Your information will only be retained by us for as long as is necessary to fulfill the purpose for which we
      collected it. Sometimes this will require us holding on to your information after the end of our contract with you
      (for example, to fulfill our legal obligations or accounting requirements).
    </Paragraph>
    <Paragraph>
      In determining the appropriate length of time to hold your personal information, we consider a variety of factors
      such as the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use
      or disclosure of your personal data, the purposes for which we process your personal data and whether we can
      achieve those purposes through other means, and the applicable legal requirements.
    </Paragraph>
    <Paragraph>We securely erase your information once it is no longer needed.</Paragraph>

    <Heading tag="h3">YOUR RIGHTS CONCERNING YOUR INFORMATION</Heading>
    <Paragraph>Under certain circumstances you have the right, by law, to:</Paragraph>
    <ul>
      <li>
        Request access to your personal information (commonly referred to as a “data subject access request”), which
        allows you to receive a copy of the personal information we hold about you and to check that we lawfully process
        it.
      </li>
      <li>
        Request correction of the personal information that we hold about you. This enables you to have any incomplete
        or inaccurate information we hold about you corrected.
      </li>
      <li>
        Request erasure of your personal information. This enables you to ask us to delete or remove personal
        information where there is no good reason for us continuing to process it. You also have the right to ask us to
        delete or remove your personal information where you have exercised your right to object to processing (see
        below).
      </li>
      <li>
        Object to processing of your personal information where we are relying on a legitimate interest (or those of a
        third-party) and there is something about your particular situation which makes you want to object to processing
        on this ground. You also have the right to object where we are processing your personal information for direct
        marketing purposes.
      </li>
      <li>
        Request the restriction of processing of your personal information. This enables you to ask us to suspend the
        processing of personal information about you, for example if you want us to establish its accuracy or the reason
        for processing it.
      </li>
      <li>Request the transfer of your personal information to another party.</li>
      <li>Withdraw your consent for us to use your personal information at any time.</li>
    </ul>
    <Paragraph>
      If you’d like to enforce any of the rights mentioned above, please contact the Data Controller at Urbanbubble
      (datacontroller@urbanbubble.co.uk). Most of the time, you can exercise any of the rights above free of charge.
      However, if your request is either unfounded or excessive, we may: (a) refuse to respond; or (b) charge you a
      reasonable administrative fee to comply with your request.
    </Paragraph>
    <Paragraph>
      Although we hope that you would never feel that you had to, you do have the right to lodge a complaint with the
      supervisory authority, The Information Commissioner’s Office – www.ico.org.uk.
    </Paragraph>
  </>
);
