import React from 'react';
import Card from '../../../components/UI/Card/Card';
import classes from './HomepageBody.module.scss';

export default () => (
  <div className="container">
    <p className={classes.homeText}>Welcome to Blackhorse Mills Health Club</p>

    <div className={classes.boxesContainer}>
      <Card label="Facilities" body="Resident only access to a fully equipped gym and a 20 metre pool." />
      <Card
        label="Personal Training"
        body="We have a range of personal training packages available. Work with one of our expert trainers to help you achieve your goals."
      />
      <Card
        label="Classes"
        body="Fitness on demand and instructor led classes to motivate and inspire you in your fitness journey."
      />
      <Card label="Book" body="Easy to book gym and swim sessions." />
    </div>
  </div>
);
