import React from 'react';
import classes from '../../components/Contact/Contact.module.scss';

export default () => (
  <article style={{ backgroundColor: '#fff', height: '100%' }}>
    <section className={classes.detailsContainer}>
      <div className="container">
        <div className={classes.contactDetails}>
          {/* <div className={classes.singleDetail}>
                            <span className={classes.label}>Telephone number:</span>
                            01904 929 540
                        </div> */}

          <div className={classes.singleDetail}>
            <span className={classes.label}>Mail:</span>
            <a href="mailto:info@blackhorsemills.com">gym@blackhorsemills.com</a>
          </div>

          <div className={classes.singleDetail}>
            <span className={classes.label}>Address:</span>
            <div>
              Wickford Way <br />
              Walthamstow <br />
              London, E17 6ZH <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
);
