import React from 'react';
import CustomButton from '../../../components/CustomButton/CustomButton';
import classes from './Hero.module.scss';
import { companyDetails } from '../../companyDetails';

export default (props) => (
  <div className={classes.hero}>
    <div className="container">
      <h1 className={classes.hero__header}></h1>

      <div className={classes.hero__box}>
        <h2 className={classes.box__header}>Resident Information</h2>

        <p className={classes.box__additionalInfo}>
          Residents must complete an induction prior to using Blackhorse Mills Health Club. Please contact the team on
          gym@blackhorsemills.com or 07710 086009 to make a booking.
        </p>

        {props.isAuthenticated ? (
          <CustomButton label="DASHBOARD" onClick={() => props.history.push(props.dashboardLink)} secondary />
        ) : (
          <CustomButton
            label="SIGN UP"
            onClick={() => props.history.push(`/gym/${companyDetails.gymId}/signup`)}
            secondary
          />
        )}
      </div>
    </div>
  </div>
);
